import React, { Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route,useRoutes, Outlet  } from 'react-router-dom';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import { DriverTraining } from './components/driverTrainingComponent/driverTraining.js';

const Main = lazy(() => import('./App'));
const Trip = lazy(() => import('./components/tripComponent/trip'));
const Download = lazy(() => import('./components/downloadComponent/download'));
const DriverDashboard = lazy(() => import('./components/driverCardsComponent/driverDashboard'));
const ReferralDashboard = lazy(() => import('./components/referralComponent/referralDashboard'));
const RefereeDashboard = lazy(() => import('./components/refereeComponent/refereeDashboard'));
const WaitTime = lazy(() => import('./components/waitTimeComponent/waitTime'));
const IndexPage= lazy(()=>import('./components/driverTrainingComponent/templates/indexPage'));

ReactDOM.render(
  <Suspense fallback={null}>
    <Outlet />
     <Router>
      <Routes>
        <Route path="/" exact element={<Main/>} />
        <Route path="/trip/" element={<Trip/>} />
        <Route path="/download/" element={<Download/>} />
        <Route path="performance" element={<DriverDashboard/>} />
        <Route path="/referrals/" element={<ReferralDashboard/>} />
        <Route path="/referee/" element={<RefereeDashboard/>} />
        <Route path="/wait-time/" element={<WaitTime/>} />
        <Route path="/training/index/*" exact element={<IndexPage/>} />
      </Routes>
      < DriverTraining />
    </Router> 

  </Suspense>,
  document.getElementById("wrapper")
);

import React from 'react';
import './driverTraining.css';
import Template01 from './templates/template01.js';
import { BrowserRouter as Router, Route, Routes, useRoutes } from 'react-router-dom';

export const DriverTraining = () => {
      const element = <Template01 />;

      const trainingVersions =
        [
              '/training/01/nyc','/training/01/nyc/english','/training/01/nyc/bengali','/training/01/nyc/spanish',
              '/training/01/nyc/01','/training/01/nyc/01/english','/training/01/nyc/01/bengali','/training/01/nyc/01/spanish',
              '/training/01/chicago','/training/01/chicago/english','/training/01/chicago/spanish','/training/01/chicago/bengali',
              '/training/01/chi','/training/01/chi/english','/training/01/chi/spanish','/training/01/chi/bengali',
              '/training/01/phl','/training/01/phl/english','/training/01/phl/spanish','/training/01/phl/bengali',
              '/training/01/dc', '/training/01/dc/english','/training/01/dc/bengali','/training/01/dc/spanish',
              '/training/01/la','/training/01/la/english','/training/01/la/spanish','/training/01/la/bengali',
              '/training/01/bos','/training/01/bos/english','/training/01/bos/spanish','/training/01/bos/bengali',
              '/training/01/miami','/training/01/miami/english','/training/01/miami/spanish','/training/01/miami/bengali',
              '/training/01/fort_lau','/training/01/fort_lau/english','/training/01/fort_lau/spanish','/training/01/fort_lau/bengali',
              '/training/01/nola','/training/01/nola/english','/training/01/nola/spanish','/training/01/nola/bengali',
              '/training/01/reno','/training/01/reno/english','/training/01/reno/spanish','/training/01/reno/bengali',
              '/training/01/sanfran','/training/01/sanfran/english','/training/01/sanfran/spanish','/training/01/sanfran/bengali',
              '/training/02/nyc','/training/02/nyc/english','/training/02/nyc/bengali','/training/02/nyc/spanish',
              '/training/02/nyc/01','/training/02/nyc/01/english',
              '/training/02/chicago','/training/02/chicago/english','/training/02/chicago/bengali','/training/02/chicago/spanish',
              '/training/02/chi','/training/02/chi/english','/training/02/chi/bengali','/training/02/chi/spanish',
              '/training/02/phl','/training/02/phl/english','/training/02/phl/spanish','/training/02/phl/bengali',
              '/training/02/dc', '/training/02/dc/english','/training/02/dc/bengali','/training/02/dc/spanish',
              '/training/02/la','/training/02/la/english','/training/02/la/spanish','/training/02/la/bengali',
              '/training/02/bos','/training/02/bos/english','/training/02/bos/spanish','/training/02/bos/bengali',
              '/training/02/miami','/training/02/miami/english','/training/02/miami/spanish','/training/02/miami/bengali',
              '/training/02/fort_lau','/training/02/fort_lau/english','/training/02/fort_lau/spanish','/training/02/fort_lau/bengali',
              '/training/02/nola','/training/02/nola/english','/training/02/nola/spanish','/training/02/nola/bengali',
              '/training/02/reno','/training/02/reno/english','/training/02/reno/spanish','/training/02/reno/bengali',
              '/training/02/sanfran','/training/02/sanfran/english','/training/02/sanfran/spanish','/training/02/sanfran/bengali',
              '/training/03/nyc','/training/03/nyc/english',
              '/training/03/chicago','/training/03/chicago/english',
              '/training/03/chi','/training/03/chi/english',
              '/training/03/phl','/training/03/phl/english',
              '/training/03/dc', '/training/03/dc/english',
              '/training/03/la','/training/03/la/english',
              '/training/03/bos','/training/03/bos/english',
              '/training/03/miami','/training/03/miami/english',
              '/training/03/fort_lau','/training/03/fort_lau/english',
              '/training/03/nola','/training/03/nola/english',
              '/training/03/reno','/training/03/reno/english',
              '/training/03/sanfran','/training/03/sanfran/english',
              '/training/04/nyc','/training/04/nyc/english',
              '/training/04/chicago','/training/04/chicago/english',
              '/training/04/chi','/training/04/chi/english',
              '/training/04/phl','/training/04/phl/english',
              '/training/04/dc', '/training/04/dc/english',
              '/training/04/la','/training/04/la/english',
              '/training/04/bos','/training/04/bos/english',
              '/training/04/miami','/training/04/miami/english',
              '/training/04/fort_lau','/training/04/fort_lau/english',
              '/training/04/nola','/training/04/nola/english',
              '/training/04/reno','/training/04/reno/english',
              '/training/04/sanfran','/training/04/sanfran/english',
              '/training/05/nyc','/training/05/nyc/english',
              '/training/05/chicago','/training/05/chicago/english',
              '/training/05/chi','/training/05/chi/english',
              '/training/05/phl','/training/05/phl/english',
              '/training/05/dc', '/training/05/dc/english',
              '/training/05/la','/training/05/la/english',
              '/training/05/bos','/training/05/bos/english',
              '/training/05/miami','/training/05/miami/english',
              '/training/05/fort_lau','/training/05/fort_lau/english',
              '/training/05/nola','/training/05/nola/english',
              '/training/05/reno','/training/05/reno/english',
              '/training/05/sanfran','/training/05/sanfran/english',
              '/training/06/nyc','/training/06/nyc/english',
              '/training/06/chicago','/training/06/chicago/english',
              '/training/06/chi','/training/06/chi/english',
              '/training/06/phl','/training/06/phl/english',
              '/training/06/dc', '/training/06/dc/english',
              '/training/06/la','/training/06/la/english',
              '/training/06/bos','/training/06/bos/english',
              '/training/06/miami','/training/06/miami/english',
              '/training/06/fort_lau','/training/06/fort_lau/english',
              '/training/06/nola','/training/06/nola/english',
              '/training/06/reno','/training/06/reno/english',
              '/training/06/sanfran','/training/06/sanfran/english',
              '/training/07/nyc','/training/07/nyc/english',
              '/training/07/chicago','/training/07/chicago/english',
              '/training/07/chi','/training/07/chi/english',
        ];

      return useRoutes(trainingVersions.map(path => ({path, element})));
}